<i18n src="@/locales/contact.json" />

<template>
  <section class="hero hero-bg-contact">
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-10">
            <div class="box">
              <div class="content">
                <h1 class="title has-text-primary">
                  {{ $t('h1Title') }}
                </h1>
                <p>
                  {{ $t('paragraph') }}
                </p>
              </div>
              <form @submit.prevent="submit()">
                <div class="columns">
                  <div class="column">
                    <!--nom prénom-->
                    <div class="columns">
                      <div class="column">
                        <ImanInput
                          id="firstName"
                          v-model="firstName"
                          :label="$t('firstNameInput.label')"
                          validator="name"
                          type="text"
                          :placeholder="$t('firstNameInput.placeHolder')"
                          :is-required="true"
                          @error="checkError"
                        />
                      </div>
                      <div class="column">
                        <ImanInput
                          id="lastName"
                          v-model="lastName"
                          :label="$t('lastNameInput.label')"
                          validator="name"
                          type="text"
                          :placeholder="$t('lastNameInput.placeHolder')"
                          :is-required="true"
                          @error="checkError"
                        />
                      </div>
                    </div>
                    <!--pays-->
                    <ImanInput
                      id="userCountry"
                      v-model="userCountry"
                      :label="$t('countryInput.label')"
                      validator="country"
                      type="text"
                      :placeholder="$t('countryInput.placeHolder')"
                      :is-required="true"
                      @error="checkError"
                    />
                    <!--telephone-->
                    <ImanInput
                      id="userPhone"
                      v-model="userPhone"
                      :label="$t('phoneInput.label')"
                      validator="phone"
                      type="tel"
                      :placeholder="$t('phoneInput.placeHolder')"
                      :is-required="false"
                      @error="checkError"
                    />
                    <!--email-->
                    <ImanInput
                      id="userEmail"
                      v-model="userEmail"
                      :label="$t('emailInput.label')"
                      validator="email"
                      typeof="mail"
                      :placeholder="$t('emailInput.placeHolder')"
                      :is-required="true"
                      @error="checkError"
                    />
                    <!--fichier joint-->
                    <div class="field">
                      <label class="label">{{ $t('fileInput.label') }}</label>
                      <div :class="fileClass">
                        <label class="file-label">
                          <input
                            id="photo-radio"
                            class="file-input"
                            type="file"
                            name="resume"
                            @change="prepareAttachment($event.target.files)"
                          >
                          <span class="file-cta">
                            <span class="file-icon">
                              <i class="mdi mdi-upload mdi-24px" />
                            </span>
                            <span
                              v-if="!fileErrorMsg"
                              class="file-label"
                            >
                              {{ $t('fileInput.buttonLabel') }}
                            </span>
                            <span
                              v-if="fileErrorMsg"
                              class="file-label"
                            >
                              {{ fileErrorMsg }}
                            </span>
                          </span>
                          <span
                            v-if="attachmentName && !fileErrorMsg"
                            class="file-name"
                          >
                            {{ attachmentName }}
                          </span>
                        </label>
                      </div>
                    </div>
                    <!--avertissement-->
                    <div class="field">
                      <p class="has-text-danger">
                        {{ $t('fileInput.warning') }}
                      </p>
                    </div>
                  </div>
                  <div class="column">
                    <!--situtation-->
                    <ImanTextarea
                      id="situation"
                      v-model="situation"
                      :label="$t('situationInput.label')"
                      validator="text"
                      :placeholder="$t('situationInput.placeHolder')"
                      :is-required="true"
                      @error="checkError"
                    />
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button
                      :disabled="isDisabled"
                      class="button is-primary"
                    >
                      {{ $t('sendButtonLabel') }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";
import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'Contact',
  mixins: [
    metaMixin
  ],
  data () {
    return {
      lastName: '',
      firstName: '',
      userEmail: '',
      userPhone: '',
      userCountry: '',
      situation: '',
      isDisabled: true,
      file: '',
      gRecaptchaResponse: '',
      errors: [],
      attachment: '',
      attachmentName: '',
      fileClass: 'file is-primary has-name',
      fileErrorMsg: ''
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../assets/img/thumbnail/MINIATURE_3.0.png')
    }
  },
  methods: {
    prepareAttachment (file) {
      // on checke qu'il y a bien un fichier à envoyer
      if (!file[0]) {
        return
      }

      // on checke la taille du fichier à envoyer (6Mo)
      if (file[0].size > 6000000) {
        this.fileClass = 'file is-danger has-name'
        this.fileErrorMsg = 'Taille maximum 6 mo'
        return
      }

      // on checke le mime type du fichier à envoyer
      const fileType = ['image/jpeg', 'image/png', 'application/pdf']

      if (fileType.indexOf(file[0].type) === -1) {
        this.fileClass = 'file is-danger has-name'
        this.fileErrorMsg = 'jpeg, png ou pdf autorisés'
        return
      }

      // gestion de l'envoi du fichier
      this.fileErrorMsg = ''
      this.fileClass = 'file is-primary has-name'
      const reader = new FileReader()
      // reader est async, on écoute l'évenement qui determine la fin du traitement
      reader.addEventListener('load', () => {
        this.attachment = reader.result
      }, false)

      // info sur la pj
      this.attachmentName = file[0].name
      // on passe la pj dans une url
      reader.readAsDataURL(file[0])
    },
    checkError (error) {
      const fieldsError = this.errors
      if (error.error) {
        fieldsError.push(error.fieldId)
        // dedoublonnage du tableau
        this.errors = fieldsError.filter((value, index, self) => {
          return self.indexOf(value) === index
        })
      } else if (error.required) {
        const fieldIndex = this.errors.indexOf(error.fieldId)
        // on supprime le fieldId qui n'est plus en erreur du tableau
        if (fieldIndex !== -1) {
          this.errors.splice(fieldIndex, 1)
        }
      }
      // on active le bouton si tous les champs sont valides
      this.isDisabled = this.errors.length !== 0
    },
    onVerify (response) {
      this.gRecaptchaResponse = response
      this.isDisabled = false
    },
    submit () {
      this.isDisabled = true
      Axios
        .post('/.netlify/functions/sendmail', {
          webSite: 'anveli.dental',
          lastName: this.lastName,
          firstName: this.firstName,
          userEmail: this.userEmail,
          userPhone: this.userPhone,
          userCountry: this.userCountry,
          situation: this.situation,
          gRecaptchaResponse: this.gRecaptchaResponse,
          attachment: this.attachment
        })
        .then((response) => {
          if(this.$i18n.locale === 'en') {
            this.$router.push({ path: '/thanks' })
          } else {
            this.$router.push({ path: '/merci' })
          }
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          if(this.$i18n.locale === 'en') {
            this.$router.push({path: '/error'})
          } else {
            this.$router.push({path: '/erreur'})
          }
        })
    }

  }
}
</script>

<style scoped>
  .hero-bg-contact {
    background-size: cover;
    background: url("../assets/img/dental-care/h1-soins-dentaires-d.jpg") no-repeat center;
    padding-bottom: 1rem;
  }
  .box {
    background-color: rgba(10, 10, 10, 0.03);
  }
</style>
